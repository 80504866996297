import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const AreasServedContainer = styled.div`
  h1 {
    font-size: calc(0.75vw + 1.5em);
  }
`

export const AreasServed = (props) => {
  const { citiesList, className, blogComponent } = props

  return (
    <AreasServedContainer className={className}>
      <div className="container is-service-page">
        <div className="pt-8 pb-5">
          <h2>Areas Served</h2>
          <ul className="flex flex-wrap list-none m-0">
            {citiesList.map((serviceArea, i) => (
              <li key={i} className="w-1/2 md:w-1/4 flex">
                <div className="flex-shrink-0 pr-1">
                  <svg
                    className="h-6 w-6 text-green-500"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>{' '}
                <Link
                  className="text-gray-800 hover:text-gray-800"
                  title={`${
                    serviceArea.title
                      ? serviceArea.title + (blogComponent ? ' Contractor' : '')
                      : ''
                  }`}
                  to={`/cities/${serviceArea.slug}/${
                    serviceArea.serviceSlug
                      ? serviceArea.serviceSlug + (!blogComponent ? '/' : '')
                      : ''
                  }`}
                >
                  {serviceArea.location}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </AreasServedContainer>
  )
}
