import React, { Component } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Card from '../components/card'
import styled from 'styled-components'
import ServiceHero from '../components/service-hero'
import CTA from '../components/cta-bar'
import PhotoGalleryComponent from '../components/photo-gallery-component'
import { OPEN_CONTACT_MODAL } from '../consts'
import { TestimonialSection } from '../components/testimonial_video'

import Popup from 'reactjs-popup'
import EstimateForm from '../components/estimate-form'
import { X } from 'react-feather'

import { AreasServed } from '../components/areas-served'
// Import css files
import ProjectPostItem from '../components/project-post-item'

import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'
import parse, { domToReact } from 'html-react-parser'

class ServiceTemplate extends Component {
  render() {
    const pageData = this.props.data.contentfulService

    const MainContentContainer = styled.div`
      p {
        color: #6f6f6f;
      }
    `

    const ProductGallerySection = styled.div`
       {
        padding-top: 30px;
      }
    `

    const WhiteBGSection = styled.section`
      background: #f9f9f9;
      padding: 2.5em 1em;
      padding-top: 0.5em;
      box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px 0px;
    `

    const TopBar = () => {
      //pageData has the data
      const topBar = pageData.topbar || []
      return topBar.map(({ id, title, cssClasses, content }) => (
        <section className={cssClasses}>
          {parse(content.content, options)}
        </section>
      ))
    }

    const SidebarContainer = styled.div``

    const BlogPostSideBarItem = ({
      title,
      slug,
      image,
      publishDate,
      author,
      page = 'blog',
      excerpt,
      className,
    }) => {
      return (
        <Link
          to={'/' + page + '/' + slug}
          className="flex mb-4 space-x-4"
          title={title}
        >
          <div className="w-24 h-24 flex-basis">
            <GatsbyImage
              image={getImage(image)}
              alt={title}
              className="rounded-lg"
            />
          </div>
          <div className="flex-1">
            <p className="hover:text-usq-red pb-1 border-b block flex text-base md:text-sm font-semibold items-top mb-0 leading-tight text-black">
              {title}
            </p>
            <p className="text-xs text-gray-500 mb-0">
              {publishDate} by {author}{' '}
            </p>
          </div>
        </Link>
      )
    }

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => {
        if (sidebar.__typename == 'ContentfulSidebar') {
          return <Card key={index} sidebar={sidebar} />
        } else if (sidebar.__typename == 'ContentfulSidebarCollection') {
          const innerCards = sidebar.sidebars.map((sidebar, index) => {
            return <Card key={'inner' + index} sidebar={sidebar} />
          })
          return innerCards
        } else return null
      })
      return sideBarCards
    }

    const options = {
      replace: ({ attribs, children, name, type, ...theRest }) => {
        if (!attribs) return
        if (
          type == 'tag' &&
          name == 'a' &&
          attribs.class.includes(OPEN_CONTACT_MODAL)
        ) {
          const ATagButton = ({ onClick }) => {
            return (
              <a
                style={{ borderWidth: '0' }}
                href={attribs.href}
                className="border-radius button-style-primary button-style-white-outline border-0 cursor-pointer primary cta-button"
                onClick={e => {
                  e.preventDefault()
                  onClick(e)
                }}
              >
                {domToReact(children, options)}
              </a>
            )
          }
          return (
            <Popup trigger={<ATagButton />} modal closeOnDocumentClick>
              {close => (
                <div className="p-2 block">
                  <button
                    style={{ borderWidth: '0', float: 'right' }}
                    className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
                    onClick={() => {
                      close()
                    }}
                  >
                    <X color={'#333'} strokeWidth={'3'} className="d-block" />
                  </button>
                  <div className="p-3 py-4 block">
                    <h2
                      className="mb-1 popupheading"
                      style={{ lineHeight: '1.05' }}
                    >
                      Request A Free Estimate!
                    </h2>
                    <p
                      style={{
                        color: '#666',
                        lineHeight: '1.25',
                        fontSize: '.9em',
                      }}
                      className="mb-3 popupdesc"
                    >
                      Solid Construction & Design will be happy to visit with
                      you and provide a FREE in-home estimate. You’ll get a
                      truly professional consultation for y`our `dream home
                      improvement project. To schedule a consultation, please
                      complete the information below.
                    </p>
                    <EstimateForm
                      showServicesList={true}
                      showLabels={true}
                      showPlaceHolders={true}
                      pageLocation={'Not provided'}
                    />
                  </div>
                </div>
              )}
            </Popup>
          )
        }
      },
    }

    const { showAreasServed } = pageData
    const { testimonials: testimonial_video_posts } =
    pageData.testimonialSection || { testimonials: [] }
    return (
      <Layout
        pageProps={this.props}
        footerLogoShowcase={pageData.footerLogoShowcase}
      >
        <SEO
          title={pageData.seoTitle}
          description={pageData.metaDescription}
          img={pageData.heroImage.metaTagImage.images.fallback.src}
          robots={pageData.metaRobotsContent}
        />
        <ServiceHero
          pageData={pageData}
          serviceTitle={pageData.serviceTitle}
          heroImage={pageData.heroImage}
          excerpt={pageData.excerpt}
          specialOffer={pageData.specialOffer}
          pageProps={this.props}
          badges={pageData.FeaturedBadges}
        />

        <SocialReviewsFullwidth modalLinkTo={`/reviews/all`} />

        {pageData &&
        pageData.photoGallery &&
        pageData.photoGallery[0].photos ? (
          <WhiteBGSection className="photogallerysec">
            <PhotoGalleryComponent gallery={pageData.photoGallery} />
          </WhiteBGSection>
        ) : null}

        <TopBar />
        <TestimonialSection testimonial_video_posts={testimonial_video_posts} />

        <div>
          {pageData &&
          pageData.productGallery &&
          pageData.productGallery[0].photos ? (
            <ProductGallerySection>
              <PhotoGalleryComponent gallery={pageData.productGallery} />
            </ProductGallerySection>
          ) : null}
        </div>

        <div className="container brand-padding-y service-page">
          <div className="row">
            <MainContentContainer
              className={
                pageData.sidebar
                  ? 'col-12 col-md-8 is-service-page'
                  : 'col-12 is-service-page'
              }
            >
              {pageData && pageData.content && pageData.content.content ? (
                <div
                  dangerouslySetInnerHTML={{ __html: pageData.content.content }}
                />
              ) : (
                ''
              )}
            </MainContentContainer>
            {pageData.sidebar ? (
              <SidebarContainer className="col-12 col-md-4">
                {this.props.data.serviceProjects &&
                  this.props.data.serviceProjects.edges.length > 0 && (
                    <div className="mb-4">
                      <h3>Our Recent Projects</h3>
                      <div className="grid grid-cols-2 gap-3">
                        {this.props.data.serviceProjects.edges.map(
                          ({ node }) => (
                            <ProjectPostItem
                              {...node}
                              key={node.id}
                              page="projects"
                              author="SCD"
                              className="mb-3"
                            />
                          )
                        )}
                      </div>
                    </div>
                  )}
                <SidebarColumn />
                {this.props.data.serviceRelatedBlogPosts &&
                  this.props.data.serviceRelatedBlogPosts.edges.length > 0 && (
                    <Card
                      sidebar={{
                        title: 'From The Blog',
                        content: { content: 'blog' },
                      }}
                    >
                      {this.props.data.serviceRelatedBlogPosts.edges.map(
                        ({ node }) => (
                          <BlogPostSideBarItem
                            {...node}
                            key={node.id}
                            author={node.author.name}
                            className="mb-3"
                          />
                        )
                      )}
                    </Card>
                  )}
              </SidebarContainer>
            ) : (
              ''
            )}
          </div>
        </div>
        {showAreasServed && (
          <AreasServed
            citiesList={this.props.pageContext.areasServed}
            serviceName={pageData.seoTitle}
            parentPageSlug={pageData.slug}
          />
        )}
        <CTA
          title={'Start Your ' + pageData.serviceTitle + ' Project Today!'}
          customTitle={pageData.footerCallToActionHeading}
          customDesc={pageData.footerCallToActionDesc}
          bgimage={pageData.footerCallToActionImg}
          serviceHeroImage={pageData.heroImage}
          btn={'Get Started!'}
          props={this.props}
        />
      </Layout>
    )
  }
}

export default ServiceTemplate

export const pageQuery = graphql`
  query serviceQuery(
    $slug: String!
    $categorySlug: String
    $relatedCategories: [String]
  ) {
    contentfulService(slug: { eq: $slug }) {
      id
      serviceTitle
      seoTitle
      metaDescription
      metaRobotsContent
      slug
      excerpt
      specialOffer
      socialReviewsFullWidth {
        id
        slug
      }
      showReputationawardsSection
      showFeaturedBenefitsSection
      featuredBenefits {
        featuredBenefits
      }
      featuredContent {
        featuredContent
      }
      content {
        content
      }
      productGallery {
        id
        title
        description {
          description
        }
        photos {
          id
          title
          thumbnail: gatsbyImageData(width: 300, height: 250)
          preview: gatsbyImageData(width: 1600)
        }
        showTitles
      }
      photoGallery {
        id
        title
        description {
          description
        }
        photos {
          id
          title
          thumbnail: gatsbyImageData(width: 300, height: 250)
          preview: gatsbyImageData(width: 1600)
        }
      }
      topbar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
      FeaturedBadges {
        title
        gatsbyImageData(width: 300)
      }
      showAreasServed
      footerLogoShowcase
      footerCallToActionHeading
      footerCallToActionDesc
      footerCallToActionImg {
        gatsbyImageData(width: 1800, placeholder: NONE)
      }

      heroImage {
        gatsbyImageData(width: 1200)
        metaTagImage: gatsbyImageData(
          width: 1200
          height: 630
          placeholder: BLURRED
        )
      }
      callToAction
      testimonialSection {
        testimonials {
          id
          title
          projectReference {
            slug
          }
          video {
            id
            title
            file {
              url
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }

      sidebar {
        __typename
        ... on ContentfulSidebar {
          id
          title
          content {
            id
            content
          }
          cssClasses
        }
        ... on ContentfulSidebarCollection {
          sidebars {
            id
            title
            content {
              id
              content
            }
            cssClasses
          }
        }
      }
    }
    serviceRelatedBlogPosts: allContentfulBlogPost(
      filter: { category: { slug: { eq: $categorySlug } } }
      limit: 6
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          # tags
          image: heroImage {
            gatsbyImageData(
              height: 120
              width: 120
              aspectRatio: 1
              resizingBehavior: CROP
              cropFocus: CENTER
              quality: 70
              placeholder: NONE
            )
          }
          metaDescription
          author {
            name
          }
        }
      }
    }
    serviceProjects: allContentfulProjects(
      filter: { category: { elemMatch: { slug: { in: $relatedCategories } } } }
      limit: 4
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            id
            name
            slug
          }
          publishDate: createdAt(formatString: "MMMM Do, YYYY")
          image: thumbnail {
            gatsbyImageData(
              height: 265
              width: 400
              aspectRatio: 1
              resizingBehavior: CROP
              cropFocus: CENTER
              quality: 70
              placeholder: NONE
            )
          }
          city
        }
      }
    }
  }
`
